(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
// jQuery(document).ready(function () {
    

// });
  
jQuery(window).on('load', function () {

  // First Lady video
    jQuery(".section-video").wrapInner('<video muted="muted" data-autoplay="" autoplay="autoplay" playsinline="playsinline" id="vid" width="100%" height="auto"><source src="./wp-content/themes/enfold-child/src/assets/file/herovideo.mp4" type="video/mp4"></video>');

    jQuery('.section-video video').on('ended', function () {
        jQuery(this).parent().addClass('hide');
    });

  // setTimeout(function() {
  //   jQuery('.section-scroll').addClass('show');
  // }, 10000);

  var callOnce = true;
      function monitorVideo() {
          if ((vid.duration - vid.currentTime) < 5)
              if (callOnce) {
                  myFunction();
                  callOnce = false;
              }
      }
      function myFunction() {
          jQuery(".section-scroll").addClass("show");
      }
      setInterval(monitorVideo, 100);

  // Looping video
    jQuery(".section-video-loop").wrapInner('<video loop="loop" muted="muted" data-autoplay="" autoplay="autoplay" playsinline="playsinline" id="loopvideo" width="100%" height="auto"><source src="./wp-content/themes/enfold-child/src/assets/file/loopvideo.mp4" type="video/mp4"></video>');
});
},{}]},{},[1])